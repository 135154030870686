<template>
	<div
		data-component="breadcrumbs"
		v-if="getBreadcrumbs"
	>
		<div data-element="breadcrumb-list">
			<router-link
				data-element="home"
				to="/"
			>
				<icon
					icon="home"
					colour="blue"
				/>
			</router-link>
			<router-link
				data-element="breadcrumb"
				v-for="(breadcrumb, key) in getBreadcrumbs"
				:key="key"
				:to="breadcrumb.path"
			>
				<span>{{ breadcrumb.text }}</span>
			</router-link>
		</div>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		computed: {
			getBreadcrumbs () {
				return this.$store.getters['ui/getBreadcrumbs'];
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='breadcrumbs'] {
		[data-element='breadcrumb-list'] {
			display:flex;
			align-items:center;
			flex-wrap:wrap;
			[data-element='home'] {
				display:flex;
				align-items:center;
				::v-deep [data-component='icon'] {
					width:auto;
					height:rem(20);
				}
				&:after {
					content:'/';
					display:inline-block;
					margin-left:0.25em;
					margin-right:0.25em;
          @include font($size: 16px, $color: $c-brand-blue, $weight: 700, $height: 20px);
				}
			}
			[data-element='breadcrumb'] {
        @include font($size: 16px, $color: $c-brand-blue, $weight: 700, $height: 20px);

				span {
					display:inline-block;
					margin-right:0.25em;
					&:hover {
						text-decoration:underline;
					}
					&:after {
						content:'/';
						display:inline-block;
						margin-left:0.25em;
					}
				}
				&:last-child {
					color: $c-brand-blue;
					margin-right:0;
					span {
						&:after {
							content:none;
						}
					}
				}
			}
		}
	}

</style>
