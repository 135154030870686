<template>
	<div class="profile-icon">
		<img src="@/assets/images/crown.png" />
	</div>
</template>

<script>

	export default {

	};
</script>

<style lang="scss" scoped>

.profile-icon {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background: $c-orange-l;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
