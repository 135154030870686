<template>
	<router-link
		:to="routeTo"
		class="course-card"
	>
		<div class="course-card__image">
			<img :src="imageUrl" alt="" />
			<div v-if="teacherMode" class="course-card__image--status" :class="course.status">
				{{ course.status }}
			</div>
		</div>
		<div class="course-card__text">
			<p class="course-card__text--title">
				{{ course.title }}
			</p>
			<p class="course-card__text--subtitle">
				{{ course.description }}
			</p>
		</div>

		<div class="course-card__info">
			<div class="course-card__info-wrapper">
				<div class="course-card__info--block tag">
					<icon icon="v2-book" colour="transparent" />
					{{ course.lessonCount }} Lessons
				</div>
				<div class="course-card__info--block tag">
					<div class="signal" :class="[course.live ? 'live' : 'online']" />
					{{ course.live ? 'LIVE' : 'ONLINE' }}
				</div>
				<div class="course-card__info--block tag">
					<icon icon="v2-shield" colour="transparent" />
					{{ getLevel }}
				</div>
			</div>

			<v2-course-progress class="course-card__info--progress" v-if="course.activated && !course.live" :value="course.activityProgress || 0" />

			<div class="course-card__info--price" :class="{free: course.isFree}" v-else-if="!course.activated">
				<div class="price-block">
					{{ course.isFree ? 'FREE' : getCost }}
				</div>
			</div>

		</div>
	</router-link>
</template>

<script>
	import Icon                               from '@/components/ui/Icon';
	import V2CourseProgress                   from '@/components/v2/courses/V2CourseProgress.vue';
	import routeParams                        from '@/mixins/routeParams';
	import { courseCost, courseDiscountCost } from '@/formatters';

	export default {
		components: {
			Icon,
			V2CourseProgress
		},
		mixins: [routeParams],
		props: {
			course: {
				type: Object,
				required: true
			},
			teacherMode: {
				type: Boolean,
				default: () => false
			},
			adminMode: {
				type: Boolean,
				default: () => false
			}
		},
		computed: {
			routeTo () {
        if (this.adminMode) {
          return `/admin/courses/${this.course.id}`;
        }
        if (this.teacherMode) {
          return `/teaching/courses/${this.course.id}`;
        }

        if (this.course.activated) {
					return `/my-courses/${this.course.id}`;
				}
				return `/browse-courses/${this.course.id}`;
			},

			imageUrl () {
        if (this.course?.media?.[0]?.url) {
          return this.course.media[0].url;
        }

				const focusTypes = {
					bidding: 'Bidding',
					'declarer play': 'Play',
					defence: 'Defence'
				};

				const focus = focusTypes[this.course.focus[0]] || 'Other';

				return `/card-images/${focus}_Lev${this.course.maxExperienceLevel}.png`;
			},

			getLevel () {
        if (this.course.minExperienceLevel === this.course.maxExperienceLevel) {
          return this.course.minExperienceLevel;
        }

        return `${this.course.minExperienceLevel}-${this.course.maxExperienceLevel}`;
			},

			getIsEnrolled () {
				if (this.getContext !== 'browseCourses') {
					return false;
				}
				if (this.course?.activated) {
					return true;
				}
				return false;
			},
			getColour () {
				if (this.getContext !== 'browseCourses') {
					return false;
				}
				if (this.course?.activated) {
					return true;
				}
				return false;
			},
			getIsLive () {
				return this.course.live;
			},
			getTeacherName () {
				return this.course?.teacherName;
			},
			getLessonsString () {
				return `Lessons: ${this.course.lessonCount}`;
			},
			getBiddingSystemsString () {
				if (this.course?.biddingSystems !== null &&
						!Array.isArray(this.course.biddingSystems)) {
					return false;
				}
				if (this.course.biddingSystems === null) {
					return 'Bidding systems: None';
				}
				if (!this.course.biddingSystems?.length) {
					return 'Bidding systems: Any';
				}
				return `Bidding systems: ${this.course.biddingSystems.join(', ')}`;
			},
			getIsFree () {
				return this.course?.isFree === true;
			},
			getCost () {
				if (this.course.isFree) {
					return false;
				}
				return courseCost(this.course);
			},
			getDiscountCost () {
				if (this.course.isFree || !this.course?.costWithDiscount) {
					return false;
				}
				return courseDiscountCost(this.course);
			},
			getIsArchived () {
				return this.course?.status === 'archived';
			},
			getIsDraft () {
				return this.course?.status === 'draft';
			},
			getIsDeleted () {
				return this.course?.status === 'deleted';
			},
			getIsComplete () {
				if (this.course?.live) {
					return this.course?.isComplete;
				}
				if (this.getContext !== 'myCourses') {
					return false;
				}
				return this.course.progress !== null;
			},
			getStatusInfoString () {
				if (!this.getIsEnrolled &&
						!this.getIsDeleted &&
						!this.getIsDraft &&
						!this.getIsArchived &&
						!this.getIsComplete) {
					return false;
				}
				const statuses = [
					(this.getIsEnrolled ? 'Already enrolled' : false),
					(this.getIsDeleted ? 'Deleted' : false),
					(this.getIsDraft ? 'Draft' : false),
					(this.getIsArchived ? 'Archived' : false),
					(this.getIsComplete ? 'Complete' : false)
				].filter(Boolean);
				return statuses.join(', ');
			}
		}
	};

</script>

<style lang="scss" scoped>
	.course-card {
    display: flex;
    flex-direction: column;
    border: 1px solid $c-gray-d2;
    border-radius: 4px;

    &__image {
      width: 100%;
      height: 180px;
      border-radius: 4px 4px 0 0;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &--status {
        position: absolute;
        right: 16px;
        top: 16px;
        border-radius: 2px;
        padding: 4px 8px;
        text-transform: uppercase;
        @include font(14px, $c-white-l, 700, 18px);

        &.published {
          background: $c-dark-navy;
        }

        &.draft {
          background: $c-light-navy;
        }
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      padding: 16px;
      justify-content: center;
      width: 100%;
      background: $c-white-l;
      position: relative;
      gap: 6px;

      &--title{
        @include font(20px, $c-dark-navy, 500, 26px);
        @include text-overflow(2);
        width: 100%;
      }

      &--subtitle{
        @include font(16px, $c-medium-navy, 400, 20px);
        @include text-overflow(2);
        width: 100%;
      }
    }

    &__info {
      display: flex;
      gap: 10px;
      padding: 12px 16px;
      align-items: flex-start;
      width: 100%;
      background: $c-gray-l;

      &-wrapper {
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
      }

      &--progress {
        width: 100px !important;
        margin-left: auto;
      }

      &--price {
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        background: $c-medium-navy;
        @include font(14px, $c-white-d, 700, 18px);

        &.free {
          color: $c-white-l;
          background: $c-green;
        }
      }
    }
  }

</style>
