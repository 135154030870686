<template>
	<nav
		class="header__nav-menu"
	>
		<router-link
			class="header__nav-menu__link"
			activeClass="header__nav-menu__link-active"
			v-for="(link, key) in getLinks"
			:key="key"
			:to="link.route"
		>
			{{ link.text }}
		</router-link>
		<div class="header__nav-menu__btn" @click="openHandle">
			Play Handle
		</div>
	</nav>
</template>

<script>
	export default {
		data: () => ({}),
		computed: {
			getLinks () {
				return [
					{
						text: 'Find courses',
						route: '/browse-courses'
					},
					(this.$store.getters['auth/getIsAuthenticated'] ?
						{
							text: 'My learning',
							route: '/my-courses'
						} : false
					),
					{
						text: 'About',
						route: '/about'
					},
					(this.$store.getters['auth/getIsTeacher'] ?
						{
							text: 'Teaching',
							route: '/teaching'
						} : false
					),
					(this.$store.getters['auth/getIsAdmin'] ?
						{
							text: 'Admin',
							route: '/admin'
						} : false
					),
					(!this.$store.getters['auth/getIsAuthenticated'] ?
						{
							text: 'Sign in',
							route: '/login'
						} : false
					)
				].filter(Boolean);
			}
		},
		methods: {
			async openHandle () {
				await this.$store.dispatch('gameEngine/openHandle', { isAuth: this.$store.getters['auth/getIsAuthenticated'] });
			}
		}
	};

</script>

<style lang="scss" scoped>

	.header__nav-menu {
    display:flex;
    gap: 12px;
    align-items: center;
    @include font();

		&__link {
			padding: 5.5px 9px;
      box-sizing: border-box;
			font-size: 20px;
      line-height: 26px;
			color: $c-dark-navy;
			border-radius:8px;
			transition: background 0.2s ease;

      &:hover {
        background: $c-white-d2;
      }

      &-active {
        border-bottom: 2px solid $c-dark-navy !important;
        border-radius: 8px 8px 0px 0px !important;
      }

		}

    &__btn {
      padding: 8px 20px;
      color: $c-white-l;
      background-color: $c-red;
      border-radius: 4px;
      box-sizing: border-box;
      cursor: pointer;
    }
	}
</style>
