<template>
	<div
		class="header__profile-button"
		@click="toggleProfileMenuIsVisible"
	>
		<div class="icon-wrapper" v-if="getShowProfileButton">
			<v2-profile-icon />
		</div>
		<div class="icon-controller">
			<icon
				icon="v2ChevronDown"
				class="icon-controller__chevron"
				colour="dark-navy"
				:class="{rotate: getIsOpen}"
			/>
		</div>
		<v2-profile-menu />
	</div>
</template>

<script>

	import Icon           from '@/components/ui/Icon';
	import V2ProfileMenu  from '@/components/v2/header/V2ProfileMenu';
	import V2ProfileIcon  from '@/components/v2/profile/V2ProfileIcon';

	export default {
		components: {
			Icon,
			V2ProfileMenu,
			V2ProfileIcon
		},
		data: () => ({

		}),
		computed: {
			getIsAuthenticated () {
				return this.$store.getters['auth/getIsAuthenticated'];
			},
			getShowProfileButton () {
				return this.getIsAuthenticated;
			},
			getIsOpen () {
				return this.$store.state.ui.profileMenuIsVisible;
			}
		},
		methods: {
			toggleProfileMenuIsVisible () {
				this.$store.commit('ui/toggleProfileMenuIsVisible');
			},
			logOut () {
				this.$store.dispatch('auth/logOut');
			}
		}
	};

</script>

<style lang="scss" scoped>
	.header__profile-button {
		position:relative;
		display:flex;
		align-items:center;
		cursor:pointer;
		user-select:none;
    margin-left: 24px;

    .icon-wrapper {
      width: 36px;
      height: 36px;
    }

    .icon-controller {
      margin-left: 9px;

      &__chevron {
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;

        &.rotate {
          transform: rotate(180deg);
        }
      }

    }

		.profile-menu {
			position: absolute;
			z-index: 2;
			top: 45px;
      right: 0px;
		}
	}

</style>
