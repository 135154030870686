<template>
	<div class="wrapper">
		<V2Button
			fit
			size="xs"
			class="back-button"
			@click="handleBack"
		>
			<icon
				icon="v2ChevronLeft"
				class="back-button__chevron"
				colour="white"
			/>
			Back
		</V2Button>
		<!-- <div class="back-button" @click="handleBack">
			<icon
				icon="v2ChevronLeft"
				class="back-button__chevron"
				colour="white"
			/>
			Back
		</div> -->
	</div>
</template>

<script>
	import Icon           from '@/components/ui/Icon';
	import V2Button from '@/components/v2/ui/V2Button';

	export default {
		components: { Icon, V2Button },
		props: {
			route: {
				default: ''
			}
		},

		methods: {
			handleBack () {
        if (this.route) {
          this.$router.push(this.route);
        } else {
          this.$router.back();
        }
			}
		}
	};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  @include font($size: 16px, $color: $c-dark-navy, $weight: 800, $height: 20px);

  .back-button {
    display: flex;
    align-items: center;
    color: $c-white-l;
    background: $c-dark-navy;
    cursor: pointer;
    padding: 2px 8px 2px 0px;
    border-radius: 4px;

    &__chevron {
      color: $c-white-l;
      padding: 5px 9px 6px 7px;
      width: 24px;
      height: 24px;
    }
  }
}

</style>
