<template>
	<div
		data-component="verification-banner"
		v-if="getShowBanner"
	>
		<p data-element="text">
			{{ getBannerText }}
		</p>
		<btn
			v-if="getShowBtn"
			:text="getBtnText"
			@click.native="restartVerification"
			size="xs"
			colour="white"
		/>
	</div>
</template>

<script>

	import Btn    from '@/components/ui/Btn';
	import dayjs  from 'dayjs';

	export default {
		components: {
			Btn
		},
		computed: {
			getIsAuthenticated () {
				return this.$store.getters['auth/getIsAuthenticated'];
			},
			getShowBanner () {
				if (!this.getIsAuthenticated) {
					return false;
				}
				if (!this.getBannerText) {
					return false;
				}
				return true;
			},
			getShowBtn () {
				if (!this.getBtnText) {
					return false;
				}
				return true;
			},
			getBtnText () {
				if (!this.getShowNotVerifiedMessage &&
						!this.getShowCompleteVerificationMessage) {
					return false;
				}
				if (this.getShowNotVerifiedMessage) {
					return 'Verify';
				}
				return 'Resend email';
			},
			getBannerText () {
				if (!this.getShowNotVerifiedMessage &&
						!this.getShowCompleteVerificationMessage &&
						!this.getShowRecentlyVerifiedMessage) {
					return false;
				}
				if (this.getShowNotVerifiedMessage) {
					return 'Your email address is not verified';
				}
				if (this.getShowCompleteVerificationMessage) {
					return 'Please check your inbox for a verification email';
				}
				return 'Your email address is now verified';
			},
			getShowNotVerifiedMessage () {
				if (this.getIsVerified) {
					return false;
				}
				if (this.getVerificationIsInProgress) {
					return false;
				}
				return true;
			},
			getShowCompleteVerificationMessage () {
				if (this.getIsVerified) {
					return false;
				}
				if (!this.getVerificationIsInProgress) {
					return false;
				}
				return true;
			},
			getShowRecentlyVerifiedMessage () {
				return this.getIsRecentlyVerified;
			},
			getVerificationIsInProgress () {
				const createdDate =  this.$store.getters['auth/getVerifyTokenCreatedDate'];
				// verification date is within the last 2 hours
				const olderThan = dayjs().subtract(2, 'hour');
				return dayjs(createdDate).isAfter(olderThan);
			},
			getIsVerified () {
				return this.$store.getters['auth/getIsVerified'];
			},
			getIsRecentlyVerified () {
				const verfiedDate =  this.$store.getters['auth/getVerifyDate'];
				const olderThan = dayjs().subtract(2, 'minute');
				return dayjs(verfiedDate).isAfter(olderThan);
			}
		},
		methods: {
			restartVerification () {
				this.$store.dispatch('auth/restartVerification', {
					credentials: this.credentials
				});
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='verification-banner'] {
		display:flex;
		align-items:center;
		width:100%;
		padding:rem(12);
		background-color: $c-dark-navy;
    margin-bottom: 24px;

		[data-element='text'] {
      @include font(14px, $c-white-l, 700, 16px)
		}
		[data-component='btn'] {
			margin:auto 0 auto auto;
		}
  }

</style>
