<template>
	<div class="footer">
		<div class="container">
			<div class="footer__name">
				{{ getCopyright }}
			</div>
			<div class="footer__service">
				<a target="_blank" href="https://www.iubenda.com/terms-and-conditions/82200802">Terms and Conditions</a>
				<a target="_blank" href="https://www.iubenda.com/privacy-policy/82200802">Privacy</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		computed: {
			getCopyright () {
				const currentYear = new Date().getFullYear().toString();
				return `Bridge128 © ${currentYear}`;
			}

		}
	};
</script>

<style lang="scss" scoped>

.footer {
  width: 100%;
  background: $c-darkest-navy;
  padding: 20px 0;
  @include font(14px, $c-light-navy, 400, 18px);

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__service {
    display: flex;
    gap: 16px;

    & * {
      text-decoration: underline;
    }
  }
}

</style>
