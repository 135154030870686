<template>
	<div class="progress-wrapper">
		<div class="progress" :style="cssVars">
			<div class="progress__bar">
				<div class="progress__bar--point">
					<icon icon="v2-bar-pointer" colour="transparent" />
				</div>
			</div>

		</div>
		<span class="percentage">
			{{ value }}%
		</span>
	</div>
</template>

<script>
	import Icon         from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},

		props: {
			value: {
				type: Number,
				required: true
			}
		},

		computed: {
			cssVars () {

				let val = `calc(${this.value}%)`;

        if (this.value >= 90) {
          val = `calc(${this.value}% - 8px)`;
        } else if (this.value >= 60) {
          val = `calc(${this.value}% - 4px)`;
        }

				return {
					'--percent': val
				};
			}
		}

	};
</script>

<style lang="scss" scoped>

.progress-wrapper {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  padding: 0 4px;

  .progress {
    width: 100%;
    height: 10px;
    position: relative;
    background: #BAC1CA;
    border-radius: 100px;

    &__bar {
      width: var(--percent);
      height: 10px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      background: $c-dark-navy;
      position: relative;
      border-radius: 100px;

      &--point {
        position: absolute;
        width: 19.2px;
        height: 19.2px;
        right: 0;
        top: 0;
        transform: translate(50%, -25%);
      }
    }

  }

  .percentage {
    @include font(14px, $c-dark-navy, 400, 18px);
    width: 35px;
    margin-left: 3px;
  }
}

</style>
