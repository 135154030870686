<template>
	<div class="top-picks">
		<div class="container">
			<div class="top-picks__header">
				<div class="top-picks__header--text">
					Top Picks for you
				</div>
				<router-link class="top-picks__header--link" to="/browse-courses">
					All courses
				</router-link>
			</div>
			<div class="top-picks__courses">
				<v2-course-card v-for="course in courses" :key="course.id" :course="course" />
			</div>
		</div>

	</div>
</template>

<script>
	import V2CourseCard          from '@/components/v2/courses/V2CourseCard.vue';
	import api                   from '@/services/api';

	export default {
		components: {
			V2CourseCard
		},

		data: () => ({
			courses: []
		}),

		created () {
			this.setCourses();
		},

		methods: {
			async setCourses () {
				const response = await api.courses.getPublishedPublicCourses({
					page: 1,
					per: 3,
					sortBy: 'min_experience_level',
					sortDir: 'ASC'
				});

        if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}

				this.courses = response;
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>

<style lang="scss" scoped>
.top-picks {
  width: 100%;
  background: $c-dark-navy;
  padding: 48px 0;

  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &--text {
      @include font(24px, $c-white-l, 500, 31px)
    }

    &--link {
      @include font(16px, $c-dark-navy, 500, 20px);
      padding: 8px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: $c-white-l;
    }
  }

  &__courses {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;

    .course-card {
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    &__courses {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 768px) {
    &__courses {
      grid-template-columns: 1fr;
    }
  }
}
</style>
