<template>
	<nav
		v-if="getIsVisible"
		class="profile-menu"
		v-click-outside="clickOutside"
	>
		<div
			v-if="getFullName"
			class="profile-menu__item profile"
		>
			<v2-profile-icon />
			{{ getFullName }}
		</div>

		<div class="spacer" />

		<V2Button
			variant="text"
			class="profile-menu__item link"
			@click="openMyProfile"
		>
			Edit profile
		</V2Button>
		<V2Button
			variant="text"
			@click="logOut"
			class="profile-menu__item link"
		>
			Log out
		</V2Button>
	</nav>
</template>

<script>
	import V2ProfileIcon  from '@/components/v2/profile/V2ProfileIcon';
	import V2Button         from '@/components/v2/ui/V2Button';

	export default {
		components: {
			V2ProfileIcon,
			V2Button
		},
		computed: {
			getIsVisible () {
				return this.$store.state.ui.profileMenuIsVisible;
			},
			getFullName () {
				return this.$store.getters['auth/getFullName'];
			},
			getIsAuthenticated () {
				return this.$store.getters['auth/getIsAuthenticated'];
			}
		},
		mounted () {
			window.addEventListener('resize', this.onResize);
		},
		beforeDestroy () {
			window.removeEventListener('resize', this.onResize);
		},
		methods: {
			onResize () {
				const mq = window.matchMedia('(min-width:899px)');
				if (mq?.matches) {
					return;
				}
				if (!this.getIsVisible) {
					return;
				}
				this.$store.commit('ui/setProfileMenuIsHidden');
			},
			logOut () {
				this.$store.dispatch('auth/logOut');
			},

			openMyProfile () {
				this.$router.push('/my-profile');
				this.clickOutside();
			},

			clickOutside () {
				this.$store.commit('ui/toggleProfileMenuIsVisible');
			}
		}
	};

</script>

<style lang="scss" scoped>
	.profile-menu {
		display:flex;
    flex-direction: column;
    gap: 16px;
		width:auto;
    padding: 16px;
    background: $c-white-l;
    cursor:default;
    border-radius:4px;
    box-shadow: 0px 0px 10px 0px #0000001A;

    .spacer {
      width: 100%;
      height: 1px;
      background: $c-white-d2;
    }

		&__item {
			display:block;
			user-select:none;
			white-space:nowrap;
      @include font(18px, $c-dark-navy, 500, 23px);

      &.profile {
        display: flex;
        gap: 12px;
        align-items: center;
        @include font(20px, $c-dark-navy, 500, 26px)
      }

      ::v-deep .text {
        text-align: left;
      }
		}
	}
</style>
