<template>
	<div class="header container">
		<v2-app-logo />

		<template v-if="!mini">
			<div class="header__wrapper">
				<v2-nav-menu />
				<v2-profile-button v-if="isAuth" />
			</div>

			<div class="header__menu" @click="toggleDrawer">
				<icon icon="v2-menu" color="transparent" />
			</div>

			<v2-app-header-menu />
		</template>

		<template v-else>
			<!-- <breadcrumbs class="breadcrumbs" /> -->
			<V2BackBtn :route="backRoute" />

			<div class="header__activity" @click="toggleLessonNav">
				<icon icon="v2-menu" color="transparent" />
			</div>
		</template>

	</div>
</template>

<script>
	import Icon from '@/components/ui/Icon';
	import V2AppLogo from '@/components/v2/header/V2AppLogo';
	import V2NavMenu from '@/components/v2/header/V2NavMenu';
	import V2ProfileButton from '@/components/v2/header/V2ProfileButton';
	import V2AppHeaderMenu from '@/components/v2/header/V2AppHeaderMenu.vue';
	// import Breadcrumbs from '@/components/ui/Breadcrumbs';
	import V2BackBtn from '@/components/v2/ui/V2BackBtn';

	export default {

		components: {
			V2AppLogo,
			V2NavMenu,
			V2ProfileButton,
			V2AppHeaderMenu,
			Icon,
			V2BackBtn
			// Breadcrumbs,

		},

		props: {
			mini: {
				type: Boolean,
				default: false
			}
		},

		computed: {
			isAuth () {
				return this.$store.getters['auth/getIsAuthenticated'];
			},

			getBreadcrumbs () {
				return this.$store.getters['ui/getBreadcrumbs'];
			},

			backRoute () {
        if (!this.getBreadcrumbs.length) {
          return undefined;
        }
				return this.getBreadcrumbs[this.getBreadcrumbs.length - 2].path;
			}
		},

		methods: {
			toggleDrawer () {
				this.$store.commit('ui/toggleDrawerIsVisible');
			},

			toggleLessonNav () {
				this.$store.commit('lesson/toggleLessonNavIsVisible');
			}
		}
	};
</script>

<style lang="scss" scoped>

.back-button {
  margin-right: auto;
  margin-left: 54px;
}

.header {

  .wrapper {
    margin-right: auto;
    margin-left: 54px;

    ::v-deep .back-button {
      height: 1.5rem;
    }
  }

  &__wrapper {
    display: flex;
  }

  &__menu {
    display: none;
    width: 23px;
    height: 18px;
  }

  &__activity {
    width: 23px;
    height: 18px;
  }

  @media (max-width: 1279px) {
    &__wrapper {
      display: none;
    }

    &__menu {
      display: flex;
    }
  }

  @media (max-width: 767px) {
    .breadcrumbs {
      display: none;
    }
  }
}
</style>
